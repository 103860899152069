function paymentCheckout(paymentGateWayConfig, studInfo, razorPayOrderId, paymentInfo, orderResponse) {
    console.log("Invoked JS file paymentCheckout");
    //window.angularRazorPayComponent.razorPayHandler();

    let paymentAmount = 0;
    _.forEach(paymentInfo.fee_collection_det, (col, cIndex) => {
        paymentAmount += parseFloat(col.amount);
    });
    paymentAmount = parseFloat(paymentAmount.toFixed(2)) * 100; //Convert to PAISE
    let RAZORPAY_OPTIONS = {
        key: paymentGateWayConfig.paymentGatewayKey,
        amount: paymentAmount,
        name: paymentGateWayConfig.clientName,
        order_id: razorPayOrderId,
        description: paymentGateWayConfig.clientDesc,
        currency: paymentGateWayConfig.currencyName,
        image: paymentGateWayConfig.clientImage,
        prefill: {
            name: studInfo[0].studentName,
            email: studInfo[0].emailId,
            contact: studInfo[0].mobileNo,
            method: ""
        },
        readonly: {
            contact: (paymentGateWayConfig.contactReadOnly == "F" ? false : true),
            email: (paymentGateWayConfig.emailReadOnly == "F" ? false : true),
            name: (paymentGateWayConfig.nameReadOnly == "F" ? false : true)
        },
        modal: {},
        theme: {
            color: paymentGateWayConfig.themeColorCd
        },
    };
    RAZORPAY_OPTIONS["handler"] = (successResponse) => {
        window.angularRazorPayComponent.razorPayHandler(JSON.stringify(RAZORPAY_OPTIONS), successResponse, paymentInfo, orderResponse);
    }

    let razorpay = new Razorpay(RAZORPAY_OPTIONS);
    razorpay.on('payment.failed', function (failedResponse) {
        window.angularRazorPayComponent.razorPayHandler(JSON.stringify(RAZORPAY_OPTIONS), failedResponse, paymentInfo, orderResponse)
    });
    razorpay.open();
}